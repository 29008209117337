/* eslint-disable consistent-return */
import { Controller } from 'stimulus';
import Swal from 'sweetalert2';

export default class extends Controller {
  static targets = ['errors', 'success', 'email', 'emailError'];

  success(event) {
    const [data, status, xhr] = event.detail;
    this.successTarget.classList.add('text-blue-600');
    this.successTarget.innerHTML = 'Thank you for submiting';
    this.errorsTarget.innerHTML = '';
  }

  errorHandler(event) {
    const [data, status, xhr] = event.detail;
    this.emailErrorTarget.innerHTML = 'Please enter your email address';
  }

  submitSurvey(e) {
    const email = this.emailTarget.value;
    if (!email) {
      this.emailErrorTarget.innerHTML = 'Please enter your email address';
      return false;
    }

    Swal.fire({
      title: '',
      html: this.messageForm(),
      confirmButtonText: 'Submit',
      showCancelButton: true,
      cancelButtonColor: '##e2e8f0',
      confirmButtonColor: '#e7ab00',
      showLoaderOnConfirm: true,
      focusConfirm: false,
      didOpen: () => {
        grecaptcha.render('contact_recaptcha', {
          sitekey: '6LfNoucdAAAAABJdoFwhy8k6_ndQ7Stfqxxsbekb',
        });
      },
      preConfirm: () => {
        if (!email) {
          Swal.showValidationMessage(`All fields are required.`);
        }
        if (email && grecaptcha.getResponse().length === 0) {
          Swal.showValidationMessage(`Please verify that you're not a robot`);
        }

        const formData = new FormData();
        formData.append('survey[email]', email);

        return fetch('/surveys', {
          body: formData,
          method: 'POST',
          credentials: 'same-origin',
          headers: {
            'X-CSRF-Token': this.getMetaValue('csrf-token'),
          },
        })
          .then((response) => response.text())
          .then((data) => {
            const responseData = JSON.parse(data);
            if (responseData.success != true) {
              throw new Error(responseData.success);
            }
            return responseData;
          })
          .catch((error) => {
            Swal.showValidationMessage(`Request failed`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: 'success',
          title: 'Thank you',
          text: 'Thank you for subscribing',
          confirmButtonText: 'OK',
          confirmButtonColor: '#191932',
        });
      }
    });
  }

  messageForm() {
    return `<form class="pt-4 mb-4" data-type="html">
      ${this.reCaptcha()}
    </form>`;
  }

  reCaptcha() {
    return `<div id="contact_recaptcha"></div>`;
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`);
    return element.getAttribute('content');
  }
}
