/* eslint-disable prefer-destructuring */

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'field',
    'name',
    'near',
    'search',
    'searchTermType',
    'searchTermValue',
    'lat',
    'lang',
  ];

  connect() {
    this.params = {};
    if (typeof google != 'undefined') {
      this.initPlaces();
    }

    const that = this;
    document.addEventListener('autocomplete.change', function (e) {
      const { value, textValue } = e.detail;

      const searchTermType = value.split('_')['1'];
      document
        .getElementById('searchTerm')
        .setAttribute('name', searchTermType);
      that.searchTermTypeTarget.value = searchTermType;
      that.searchTermValueTarget.value = value;
    });

    if (document.getElementById('map_toggle')) {
      var map = document.getElementById('map_toggle');
      map.addEventListener('change', (event) => {
        document.getElementById('doctor_cards').classList.toggle('lg:w-8/12');
        document.getElementById('doctor_cards').classList.toggle('lg:w-full');
        document
          .getElementsByClassName('map-container')[0]
          .classList.toggle('lg:block');
      });
    }
  }

  initPlaces() {
    const options = {
      componentRestrictions: { country: ['us', 'pr', 'vi', 'gu', 'mp'] },
    };
    this.autocomplete = new google.maps.places.Autocomplete(
      this.fieldTarget,
      options
    );
    // this.autocomplete.bindTo('bounds', this.map)
    this.autocomplete.setFields([
      'address_components',
      'geometry',
      'icon',
      'name',
    ]);
    this.autocomplete.addListener(
      'place_changed',
      this.placeChanged.bind(this)
    );
  }

  placeChanged() {
    const place = this.autocomplete.getPlace();
    const name = place.name;
    const near = `${place.geometry.location.lat()},${place.geometry.location.lng()}`;

    const { value } = this.nameTarget;
    const paramHash = { near, place: name };
    if (value !== undefined && value !== null && value !== '') {
      this.setParams();
    }
    this.params = paramHash;
  }

  submitSearch(event) {
    event.preventDefault();
    let url = '';
    this.setParams();
    const queryString = new URLSearchParams(this.params || {}).toString();
    if ('doctor_fdd_id' in this.params) {
      url = `/to-doctor-profile?doctor_fdd_id=${this.params.doctor_fdd_id}`;
    } else if ('clinic_name' in this.params) {
      url = `/clinic/${this.params.clinic_name}`;
    } else {
      url = `/search-map?${queryString}`;
    }
    Turbolinks.visit(url);
  }

  setParams() {
    const placeName = this.fieldTarget.value;
    if (placeName) this.params.place = placeName;

    const value = this.nameTarget.value;
    if (value !== undefined && value !== null && value !== '') {
      if (this.searchTermTypeTarget.value == 'clinic') {
        this.params.clinic_name = this.convertToSlug(value);
      } else if (this.searchTermTypeTarget.value == 'doctor') {
        this.params.doctor_name = value;
        const doctorValueString = this.searchTermValueTarget.value;
        this.params.doctor_fdd_id = doctorValueString.split('_')['2'];
      } else if (this.searchTermTypeTarget.value == 'treatment') {
        this.params.condition_slug = this.convertToSlug(value);
      } else if (this.searchTermTypeTarget.value == 'procedure') {
        this.params.procedure_slug = this.convertToSlug(value);
      } else if (this.searchTermTypeTarget.value == 'specialtyGroup') {
        const specialityGroupValueString = this.searchTermValueTarget.value;
        this.params.specialty_group =
          specialityGroupValueString.split('_')['2'];
      } else {
        const specialityValueString = this.searchTermValueTarget.value;
        this.params.specialty = specialityValueString.split('_')['2'];
      }
    }
  }

  convertToSlug(text) {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }
}
