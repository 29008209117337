import 'controllers/home';
import Glide from '@glidejs/glide';
// import 'alpinejs';

require('turbolinks').start();
require('@rails/ujs').start();

const images = require.context('../images', true);
/* eslint-disable no-unused-vars */
const imagePath = (name) => images(name, true);

window.initPlaces = function (...args) {
  const event = document.createEvent('Events');
  event.initEvent('google-maps-callback', true, true);
  event.args = args;
  window.dispatchEvent(event);
};

window.glidejshomeGlobal = function() {
      if (document.querySelector('.glide1') != null) {
        const slider = new Glide('.glide1', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 2,
          slidesToScroll: 1,
          keyboard: true,
          gap: 10,
          bound: true,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 80,
          },
          breakpoints: {
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 80,
              },
              gap: 8,
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 60,
              },
              gap: 2,
            },
          },
        });
        slider.mount();
      }
      if (document.querySelector('.glide2') != null) {
        const slider2 = new Glide('.glide2', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 2,
          slidesToScroll: 1,
          keyboard: true,
          bound: true,
          gap: 10,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 80,
          },
          breakpoints: {
            1024: {
              perView: 2,
              peek: {
                before: 0,
                after: 80,
              },
              gap: 8,
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 60,
              },
              gap: 2,
            },
          },
        });
        slider2.mount();
      }
      if (document.querySelector('.glide3') != null) {
        const slider3 = new Glide('.glide3', {
          type: 'slider',
          focusAt: 0,
          startAt: 0,
          perView: 1,
          fixWidth: true,
          slidesToScroll: 1,
          keyboard: true,
          gap: 7,
          bound: true,
          loop: true,
          rewind: true,
          peek: {
            before: 0,
            after: 350,
          },
          breakpoints: {
            2560: {
              perView: 4,
              gap: 5,
              peek: {
                before: 0,
                after: 0,
              },
            },
            1770: {
              perView: 2,
              gap: 5,
              peek: {
                before: 0,
                after: 0,
              },
            },
            1440: {
              perView: 2,
              gap: 5,
              peek: {
                before: 10,
                after: 10,
              },
            },
            1024: {
              perView: 2,
              gap: 5,
              peek: {
                before: 0,
                after: 0,
              },
            },
            600: {
              perView: 1,
              peek: {
                before: 0,
                after: 0,
              },
              gap: 2,
            },
          },
        });
       slider3.on('mount.after', function () {
          const viewportWidth = window.innerWidth; // Get browser viewport width
          if(viewportWidth >= 768){
            const slides = document.querySelector('.telehealth_slider_ul');
            const childElem = slides.children;
            for (let index = 0; index < childElem.length; index++) {
              const element = childElem[index];
              const currentWidth = parseInt(element.style.width.split("px")[0], 10);
              // Add 50 if the screen size is small (e.g., less than 768px), otherwise subtract 50
              const adjustedWidth =  0;
              if (viewportWidth > 1700 && viewportWidth <= 1800) {
                adjustedWidth = currentWidth - 175;
              } else {
                adjustedWidth = viewportWidth <= 1024
                              ? currentWidth + 50
                              : currentWidth - 75;
              }
              element.style.width = adjustedWidth + 'px';
              console.log("width changed");
            }
          }
        });
        slider3.on('resize', function () {
          const viewportWidth = window.innerWidth; // Get browser viewport width
          const slides = document.querySelector('.telehealth_slider_ul');
          const childElem = slides.children;
          for (let index = 0; index < childElem.length; index++) {
            const element = childElem[index];
            const currentWidth = parseInt(element.style.width.split("px")[0], 10);
             // Add 50 if the screen size is small (e.g., less than 768px), otherwise subtract 50
            if (viewportWidth > 1700 && viewportWidth <= 1800) {
                adjustedWidth = currentWidth - 175;
            } else {
              adjustedWidth = viewportWidth <= 1024
                            ? currentWidth + 50
                            : currentWidth - 75;
            }
            element.style.width = adjustedWidth + 'px';
            console.log("width changed");

          }
        });
        slider3.mount();
      }
  }
