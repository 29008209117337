import { Controller } from 'stimulus';

window.inputlist = [];
export default class extends Controller {
  connect() {
    this.tabsjs();
    this.tablistjshome();
    this.tablistjshome2();
    this.tablistjshome3();
    this.hoverOnLi();
    this.keypressOnLi();
  }

  keypressOnLi() {
    document.querySelector('input[name="speciality"]').onkeydown = function () {
      inputlist = document.querySelectorAll('ul.autocomplete li');
    };
  }

  hoverOnLi() {
    for (var i = 0; i < inputlist.length; i++) {
      // eslint-disable-next-line no-loop-func
      inputlist[i].onmouseover = function () {
        console.log('inputlist[i].textContent');
        document.querySelector('input[name="speciality"]').value =
          inputlist[i].textContent;
      };
    }
  }

  tabsjs() {
    const tabs = document.querySelector('.wrapper');
    const tabButton = document.querySelectorAll('.tab-button');
    const contents = document.querySelectorAll('.content');

    tabs.onclick = (e) => {
      const { id } = e.target.dataset;
      if (id) {
        tabButton.forEach((btn) => {
          btn.classList.remove('job-tab-active');
        });
        e.target.classList.add('job-tab-active');
        contents.forEach((content) => {
          content.classList.remove('active');
          content.classList.add('hidden');
        });
        const element = document.getElementById(id);
        element.classList.add('active');
        element.classList.remove('hidden');
      }
    };
  }

  tablistjshome() {
    window.addEventListener('load', () => {
      var liItems = document.getElementsByClassName('htab-list');
      var loadMore =
        document.querySelector('.htab-list').parentElement.lastChild
          .previousElementSibling;
      var flag = 1;
      var loaditems = 6;
      if (liItems.length <= loaditems) {
        loadMore.classList.add('htab-list-hidden');
        for (var i = 0; i < liItems.length; i++) {
          liItems[i].style.display = 'block';
        }
      } else {
        for (var j = 0; j < loaditems; j++) {
          liItems[j].style.display = 'block';
        }
        flag++;
        loadMore.onclick = function () {
          for (var k = 0; k < loaditems * flag; k++) {
            if (k >= liItems.length) {
              loadMore.classList.add('htab-list-hidden');
            } else {
              liItems[k].style.display = 'block';
            }
          }
          flag++;
        };
      }
    });
  }

  tablistjshome2() {
    window.addEventListener('load', () => {
      var liItems = document.getElementsByClassName('htab-list-2');
      var loadMore =
        document.querySelector('.htab-list-2').parentElement.lastChild
          .previousElementSibling;
      var flag = 1;
      var loaditems = 6;
      if (liItems.length <= loaditems) {
        loadMore.classList.add('htab-list-hidden');
        for (var i = 0; i < liItems.length; i++) {
          liItems[i].style.display = 'block';
        }
      } else {
        for (var j = 0; j < loaditems; j++) {
          liItems[j].style.display = 'block';
        }
        flag++;
        loadMore.onclick = function () {
          for (var k = 0; k < loaditems * flag; k++) {
            if (k >= liItems.length) {
              loadMore.classList.add('htab-list-hidden');
            } else {
              liItems[k].style.display = 'block';
            }
          }
          flag++;
        };
      }
    });
  }

  tablistjshome3() {
    window.addEventListener('load', () => {
      var liItems = document.getElementsByClassName('htab-list-3');
      var loadMore =
        document.querySelector('.htab-list-3').parentElement.lastChild
          .previousElementSibling;
      var flag = 1;
      var loaditems = 6;
      if (liItems.length <= loaditems) {
        loadMore.classList.add('htab-list-hidden');
        for (var i = 0; i < liItems.length; i++) {
          liItems[i].style.display = 'block';
        }
      } else {
        for (var j = 0; j < loaditems; j++) {
          liItems[j].style.display = 'block';
        }
        flag++;
        loadMore.onclick = function () {
          for (var k = 0; k < loaditems * flag; k++) {
            if (k >= liItems.length) {
              loadMore.classList.add('htab-list-hidden');
            } else {
              liItems[k].style.display = 'block';
            }
          }
          flag++;
        };
      }
    });
  }
}
